import React, {Component} from "react";
import { Container, Row, Col,   Button,  DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';
import Post from './Post';
import {getApiBase, getApiPath, humanFileSize} from "./Utils.js";
import TagManager from "./TagManager.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSquare, faSquareCheck, faXmarkCircle, faHardDrive, faImages } from "@fortawesome/free-solid-svg-icons";

class Stash extends Component 
{
    constructor(props) {
        super(props);
        this.state = {
            posts : [],        
            filterTags: [],
            user: null,
            searchString: "",
            supported_content_types: [],
            filter_content_type: []
        }
    }

    getSupportedContentTypes = () => {
        const apiPath = getApiBase() + "/supported_content_types";

        fetch(apiPath)
        .then ( response => response.json())
        .then( data => {
            this.setState( { supported_content_types: data })
        } );

    }

    getStash = () => {
        const apiPath = getApiPath("stash", localStorage.getItem("apiKey"), "");      
        var qsStrings = []

        if (this.state.filterTags.length > 0)
        {
            qsStrings.push("t=" + this.state.filterTags.join(",") );
        }
        
        if (this.state.searchString !== "")
        {
            qsStrings.push("s=" + encodeURIComponent( this.state.searchString ) )
        }

        if (this.state.filter_content_type.length > 0 )
        {
            qsStrings.push("ct=" + this.state.filter_content_type.join(",") );
        }

        var qs = "";

        if (qsStrings.length > 0)
            {
                qs = "?" + qsStrings.join("&")
            }
        
        fetch(apiPath  + qs)
        .then(response => response.json())
        .then(data => {
            // console.dir(data)
            this.setState({ posts: data})
        })
    }

    
    getUser = () => {
        const tagsApiPath = getApiPath("user", localStorage.getItem("apiKey"), "");

        fetch(tagsApiPath)
        .then(response => response.json())
        .then(data => {           
            this.setState({user: data } )
        })
    }

    componentDidMount = () => {
        this.refreshTagsAndStash();
    }

    refreshTagsAndStash = () => {
        this.getStash();
        this.getUser();
        this.getSupportedContentTypes();
    }
  
    signalFlush = (post) => {
        console.dir(post)   
        this.getStash();     
    }

    toggleTag = (tag) => {
        var filterTags = this.state.filterTags;

        if (filterTags.indexOf(tag) === -1 ) {
            filterTags.push(tag)
        }
        else {
            filterTags.splice( filterTags.indexOf(tag) , 1)
        }

        this.setState({ filterTags: filterTags})
        this.getStash();
    }

    onSearchStringChange = (e) => {
        this.setState( { searchString: e.target.value })
    }

    clearSearchString = () => {
        this.setState( { searchString : "" }, () => this.getStash() )                    
    }

    handleKeyDown = (e) => {
        if (e.key === "Enter")
        {
            this.getStash();
        }
    }

    // filterContentType = (ct) => {
    //     this.setState({ filter_content_type: ct },
    //         this.getStash)
    // }

    toggleType = (ct) => {
        var filterCT = this.state.filter_content_type;

        if (filterCT.indexOf(ct) === -1 ) {
            filterCT.push(ct)
        }
        else {
            filterCT.splice( filterCT.indexOf(ct) , 1)
        }

        this.setState({ filter_content_type: filterCT})
        this.getStash();
    }

    render() {

        if (this.state.user === undefined || this.state.user === null ){
            return "";
        }

        var posts = this.state.posts.map( (p) => {
            return (
                <Post 
                    signalPostSaved={this.getStash}
                    signalTagToggle={this.getStash} 
                    allTags={this.state.user.tags} 
                    tagsForPicker={this.state.tagsForPicker} 
                    key={p._id} 
                    signalFlush={this.signalFlush} 
                    post={p}></Post>
            )
        })
        
      var cts = this.state.supported_content_types.map( (ct, ix) => {
        return (
            <Dropdown.Item id={ix} label={ct} name={ct} key={`content_type_${ix}`} 
                onClick={ () => { this.toggleType(ct)} }  >
                <FontAwesomeIcon icon={  this.state.filter_content_type.includes(ct) ? faSquareCheck : faSquare } /> {ct}
            </Dropdown.Item>
            
        )
      } )
        
        var tags2 = this.state.user.tags.map( (tag, ix ) =>{
            return (
                <Dropdown.Item key={`check-${tag}`} className='btn-secondary'
                    id={ix}
                    label={tag} 
                    name={tag}  
                    onClick={ () => { this.toggleTag(tag)} }
                    > <FontAwesomeIcon icon={  this.state.filterTags.includes(tag) ? faSquareCheck : faSquare } /> {tag}</Dropdown.Item>
            )
        })     

        return (            
            <Container className='mt-2'>                
                <Row className='row'>                                        
                    <Col>                           
                                
                        <input placeholder="Search" type='text' className="stash-search-input" value={this.state.searchString} onChange={this.onSearchStringChange} onKeyDown={this.handleKeyDown} />
                        <Button variant="secondary" className='stash-search-button' onClick={this.getStash}><FontAwesomeIcon icon={faSearch} />&nbsp;Search</Button>
                        <Button variant='secondary' className='stash-clear-button' onClick={this.clearSearchString} ><FontAwesomeIcon icon={ faXmarkCircle } /></Button> 


                        <DropdownButton className='stash-content-type-dropdown-button'
                            variant="secondary"
                            as={ButtonGroup}
                            title= { ("Type: " + (this.state.filter_content_type === null ? "(all)" : this.state.filter_content_type) ) }
                        >
                           
                            {cts }
                        </DropdownButton>

                        <DropdownButton className='stash-tag-dropdown-button'
                            variant="secondary"
                            as={ButtonGroup}
                            title= { ( "Tags: " + this.state.filterTags.join(" ") ) }
                            autoClose="outside" 
                        >
                            {tags2}
                        </DropdownButton>
                        
                        <TagManager className='stash-tag-manager' tags={this.state.user.tags} signalTagsEdited={this.refreshTagsAndStash}></TagManager>   

                        
                        <Button className='stash-results-count' variant='outline-secondary' title={this.state.posts.length + " posts found"}>
                            <FontAwesomeIcon icon={faImages} /> {this.state.posts.length } Found
                        </Button>

                        <Button className='stash-size' variant="outline-secondary" title="Your stash storage used.">
                            <FontAwesomeIcon icon={faHardDrive} className='post-detail-icon-col' />{ humanFileSize(this.state.user.stash_size, false, 1) }
                        </Button>
                    </Col>
                </Row>

                <Container className='stash'>
                    {
                        this.state.posts !== undefined && this.state.posts.length > 0 ? posts : "No posts found"
                    }
                </Container>                
            </Container>            
        )
    }
}


export default Stash
